@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

:root{
    --navbar: #e9e9e9;
    --spinnerAnim: #000;
    --foregroundBox: #f1f1f1;
    --button: #cfcfcf;
    --accent: #ff8000;
    --popupBkg: #e6e6e6;
    --error: #e24e09;
    --buttonWarn: #691717;
    --shadow: #dddddd6c;
    --darkTx: #545454;
    --bkg: #fff;
    --tx: #000;
    --inputBkg: #fff;
}

/* @media (prefers-color-scheme: dark){
    :root{
        --navbar: #252525;
        --spinnerAnim: rgb(255, 255, 255);
        --foregroundBox: #292929;
        --button: #3b3b3b;
        --accent: #d46b01;
        --popupBkg: #2c2c2c;
        --error: #e24e09;
        --buttonWarn: #691717;
        --shadow: #1616166c;
        --darkTx: #a5a5a5;
        --bkg: #000;
        --tx: #fff;
        --inputBkg: #000;
    }
} */

body{
    box-sizing: border-box;
    font-family: sans-serif;
    background-color: var(--bkg);
    color: var(--tx);
    font-family: 'Ubuntu', sans-serif;
}
*{
    margin: 0;
    padding: 0;
    color: var(--tx);
}
.hidden{
    display: none;
}
.video404{
    width: 50%;
    aspect-ratio: 16/9;
}

@media only screen and (max-width: 900px) {
    .video404{
        width: 100%;
        aspect-ratio: 16/9;
    }
}
.dummyExtender{
    margin-bottom: 16rem;
}
button{
    cursor: pointer;
}

.nunito{
   font-family: 'Nunito', sans-serif;
}
.ubuntu{
   font-family: 'Ubuntu', sans-serif;
}

.firebase-emulator-warning{
    display: none;
}
